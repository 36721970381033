<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="rejectBdo"
        ref="rejectBdo"
        class="custom-form pt-6"
        @submit.prevent="reject"
      >
        <v-col cols="12">
          <v-textarea
            v-model="remarks"
            outlined
            :rows="3"
            auto-grow
            hide-details
            label="Uwagi"
            placeholder="Wpisz uwagi"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="rejectBdo"
      >
        Odrzuć
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper,
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      remarks: '',
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.bdoCard.isProcessing,
      bdoCard: state => state.bdoCard.entity,
    }),
  },
  mounted() {
    const { reportedWeight, revisedWeight, reportedDebrisType, revisedDebrisType } = this.bdoCard
    if (reportedWeight && revisedWeight && Number(reportedWeight) !== Number(revisedWeight)) {
      this.remarks += `Błędna waga – prawidłowa to ${reportedWeight}\n`
    }
    if (reportedDebrisType && revisedDebrisType && reportedDebrisType.code !== revisedDebrisType.code) {
      this.remarks += `Błędny kod odpadu – prawidłowy to ${reportedDebrisType.code}`
    }
  },
  methods: {
    ...mapActions({
      rejectBdoCard: 'bdoCard/rejectBdoCard',
    }),
    reject () {
      if (this.$refs.rejectBdo.validate()) {
        this.rejectBdoCard(this.remarks)
          .then(() => {
            this.afterFormSubmitted('Odrzucono kartę')
          })
      }
    }
  }
}
</script>
